const convertNumToTime = function(number) {

    // Separate the int from the decimal part
    var hour = Math.floor(number);
    var decpart = number - hour;

    var min = 1 / 60;
    // Round to nearest minute
    decpart = min * Math.round(decpart / min);

    var minute = Math.floor(decpart * 60) + '';

    // Concate hours and minutes
    var time = hour + 'h ' + minute + 'min';

    return time;
}

export default convertNumToTime